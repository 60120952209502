import request from './request'
import {
    ProjectDto,
    QueryPageDto,
    QueryDateSlotDto
} from './dto'

interface GetProjectPeopleDto extends QueryPageDto {
    projectId: number
}
interface GetProjectPeopleResDto extends QueryPageDto{
    serveCompanyId: number | string
    totalCount: number,
    list: ProjectDto[],
    serveCompanyName: string,
    projectName: string,
    createTime: string,
}
// 获取员工工作过的项目列表
export function getProjectPeople (params:GetProjectPeopleDto): Promise<GetProjectPeopleResDto> {
    return request.get('/rest/pay/payWorkerProjectRecord/list', {
        params
    })
}

interface GetEnterpriseProjectDto extends QueryPageDto {
    serveCompanyId: number
}
interface getEnterpriseProjectResDto{
    totalCount: number
    list: ProjectDto[]
    serveCompanyName: string
    progressCount: number
    ppCount: number
}
// 获取派遣单位项目列表
export function getEnterpriseProject (params:GetEnterpriseProjectDto): Promise<getEnterpriseProjectResDto> {
    return request.get('/rest/pay/payProject/list', {
        params
    })
}
interface GetProjectResDto{
    totalCount?: number
    total?: number,
    list: ProjectDto[],
    serveCompanyName?:string,
    createTime?:string,
    projectName?:string
}
interface GetPlaceProjectDto extends QueryPageDto{
    beginCreateTime:string,
    endCreateTime:string,
    beginArchivedTime:string,
    endArchivedTime:string,
}
// 获取归档的项目列表
export function getPlaceProject (
    params: GetPlaceProjectDto
): Promise<GetProjectResDto> {
    return request.get('/rest/pay/payProject/archived-list', {
        params
    })
}
interface GetPlaceProjectDetailDto extends QueryPageDto{
    projectId:number
}

// 归档项目详情列表rest/pay/payWorkerProjectRecord/list
export function getPlaceProjectDetail (
    params: GetPlaceProjectDetailDto
): Promise<GetProjectResDto> {
    return request.get('/rest/pay/payWorkerProjectRecord/list', {
        params
    })
}

// 归档项目
export function placeProject (data:{archivedIds: string[], isArchived: 0|1, archivedRemark?: string}): Promise<void> {
    return request.put('/rest/pay/payProject/archive', data)
}
interface GetSignProjectListDto extends QueryPageDto, QueryDateSlotDto {}

// 按项目获取该公司下打卡信息
export function getSignProjectList (data: GetSignProjectListDto): Promise<{ list: ProjectDto[]; total: number }> {
    return request.get('/rest/pay/blueSign/sign-info-project', {
        params: data
    })
}

// 归档项目批量删除功能
export function deleteArchive (data:string | string[]): Promise<void> {
    return request.put('/rest/pay/payProject/delete-archive', data)
}
