
import { defineComponent } from 'vue'
import { getPlaceProjectDetail } from '@/api/project'
import { ProjectDto } from '@/api/dto'
import { regressionFileExport } from '@/api/export'
import store from '@/store/index'
import axios from 'axios'
import { downLoadFile } from '../utils/util'
export default defineComponent({
    name: 'ProjectPlaceDetail',
    data () {
        return {
            tableData: [] as ProjectDto[],
            pageNum: 1,
            pageSize: 10,
            total: 0 as number | undefined,
            searchText: '',
            selects: [] as string[],
            serveCompanyName: '' as string | undefined,
            projectName: '' as string | undefined,
            createTime: '' as string | undefined
        }
    },
    created () {
        this.handleCurrentChange()
    },
    methods: {
        async handleCurrentChange () {
            const result = await getPlaceProjectDetail({
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                projectId: Number(this.$route.params.id) as number,
                searchText: this.searchText
            })
            this.tableData = result.list
            this.total = result.totalCount
            this.serveCompanyName = result.serveCompanyName
            this.projectName = result.projectName
            this.createTime = result.createTime
        },
        handleSizeChange () {
            this.pageNum = 1
            this.handleCurrentChange()
        },
        search () {
            this.pageNum = 1
            this.handleCurrentChange()
        },
        onReset () {
            this.searchText = ''
            this.search()
        },
        async onExport () {
            const result = await regressionFileExport({
                ids: this.$route.params.id
            }, {
                loading: true
            })
            if (!result.fileUrl && result.exportStatus === 0) {
                store.commit('getLongPollingFile', result)
            } else {
                axios({
                    url: result.fileUrl,
                    method: 'GET',
                    responseType: 'blob'
                }).then(async (response) => {
                    await downLoadFile(response, result.exportType, result.updateTime, result.remark)
                    await this.$message.success('导出成功！')
                })
            }
            console.log(result, 'result')
            // window.location.href =
            //     process.env.VUE_APP_BASE_URL +
            //         '/common/download?delete=true&fileName=' +
            //         result

            // this.$message.success('导出成功！')
        },
        handleSelectionChange (selects: { id: string }[]) {
            this.selects = selects.map(item => item.id)
        }
    }
})
